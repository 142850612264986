type LocationTestType = '30sec' | '90min'
type ScheduledTestType =
  | 'automatic_annual'
  | 'automatic_monthly'
  | 'manual'
  | 'system'

type ScheduledType =
  | 'manual_location'
  | 'manual_sitewide'
  | 'automatic_annual'
  | 'automatic_monthly'

type LocationType = {
  location_id: string
}

type PostTestType = {
  testType: LocationTestType
  locationId: string
  scheduledDatetime: 'now' | Date
}

type PostTestResponseType = {
  data: LocationType[]
}

type TestCycle = {
  beginning_at: string
  id: string
}

type TestCyclesResponse = {
  data: TestCycle[]
}

type TestStatus =
  | 'failed'
  | 'passed'
  | 'pending'
  | 'scheduled'
  | 'rescheduled'
  | 'running'

type TestHistory = {
  id: string
  rescheduledFor: string | null
  sensorResults: SensorResults[]
  startedAt: string
  status: TestStatus
  testType: LocationTestType
  scheduledType: ScheduledType
}

type TestHistoryResponse = {
  id: string
  rescheduled_for: string | null
  sensor_results: SensorResults[]
  started_at: string
  status: TestStatus
  test_type: LocationTestType
  scheduled_type: ScheduledType
}

type UpcomingTest = {
  id: string
  isAutoScheduled: boolean
  isSiteWide: boolean
  processingEndedAt: Date | string | null
  processingStartedAt: Date | string | null
  scheduledFor: string
  testType: LocationTestType
}

type UpcomingTestResponse = {
  id: string
  is_auto_scheduled: boolean
  is_site_wide: boolean
  processing_ended_at: Date | null
  processing_started_at: Date | null
  scheduled_for: string
  test_type: LocationTestType
}

type TestCycleResultsResponseLocation = {
  id: string
  name: string
  test_results: TestResultsResponse[]
}

type TestCycleResultsResponse = {
  id: string
  beginning_at: string | Date
  test_cycle_locations: TestCycleResultsResponseLocation[]
  gateway_id: string
}

type TestResultsResponse = {
  id: string
  ended_at: string
  scheduled_for: string
  scheduled_type: ScheduledTestType
  sensor_results: SensorResults[]
  started_at: string
  status: TestStatus
  test_type: LocationTestType
}

type TestErrorResultDisplayString =
  | 'Bad Battery Likely'
  | 'Bad Lamp Likely'
  | 'Light Interference'
  | 'No Light Detected'
  | 'Offline'
  | 'Sensor Error'
  | 'Unverified'

type TestErrorResult =
  | 'bad_battery_detected'
  | 'bad_light_fixture_detected'
  | 'invalid_sensor_data'
  | 'no_change_in_light_detected'
  | 'external_light_corrupted_test'

function isTestErrorResultDisplayString(
  testString: unknown
): testString is TestErrorResultDisplayString {
  return (
    typeof testString === 'string' &&
    testString !== null &&
    [
      'Bad Battery Likely',
      'Bad Lamp Likely',
      'Light Interference',
      'No Light Detected',
      'Offline',
      'Sensor Error',
      'Unverified',
    ].includes(testString)
  )
}

function isTestErrorResult(testString: unknown): testString is TestErrorResult {
  return (
    typeof testString === 'string' &&
    testString !== null &&
    [
      'bad_battery_detected',
      'bad_light_fixture_detected',
      'invalid_sensor_data',
      'no_change_in_light_detected',
      'external_light_corrupted_test',
    ].includes(testString)
  )
}

type SensorResults = {
  sensor: number
  message: TestErrorResult
}

type TestResults = {
  id: string
  endedAt: string
  scheduledFor: string
  scheduledType: ScheduledTestType
  sensorResults: SensorResults[]
  startedAt: string
  status: TestStatus
  testType: LocationTestType
}

type TestCycleResultsLocation = {
  id: string
  name: string
  testResults: TestResults[]
}

type TestCycleResults = {
  id: string
  beginningAt: string | Date
  testCycleLocations: TestCycleResultsLocation[]
  gatewayId: string
}

export type YearlyTestsRequestParams = {
  organizationId: string
  siteId: string
}

type YearlyTestResultsResponse = {
  id: string
  location: string
  started_at: string
  ended_at: string
  status: TestStatus
}

type YearlyTestResponse = {
  scheduled_for: string | Date
  scheduled_type: ScheduledTestType
  test_type: LocationTestType
  test_results: YearlyTestResultsResponse[]
  gateway_id: string
}

type YearlyTestResult = {
  id: string
  location: string
  startedAt: string
  endedAt: string
  status: TestStatus
  testType: LocationTestType
  scheduledType: ScheduledTestType
}

type YearlyTest = {
  scheduledFor: string | Date
  gatewayId: string
  testResults: YearlyTestResult[]
}

const testErrorMappings: Record<TestErrorResult, TestErrorResultDisplayString> =
  {
    bad_battery_detected: 'Bad Battery Likely',
    bad_light_fixture_detected: 'Bad Lamp Likely',
    invalid_sensor_data: 'Sensor Error',
    no_change_in_light_detected: 'No Light Detected',
    external_light_corrupted_test: 'Light Interference',
  }

export { isTestErrorResult, isTestErrorResultDisplayString, testErrorMappings }
export type {
  LocationTestType,
  LocationType,
  PostTestResponseType,
  PostTestType,
  ScheduledType,
  ScheduledTestType,
  SensorResults,
  TestCycle,
  TestCycleResults,
  TestCycleResultsLocation,
  TestCycleResultsResponse,
  TestCycleResultsResponseLocation,
  TestCyclesResponse,
  TestHistory,
  TestHistoryResponse,
  TestResults,
  TestResultsResponse,
  TestStatus,
  TestErrorResult,
  TestErrorResultDisplayString,
  UpcomingTest,
  UpcomingTestResponse,
  YearlyTest,
  YearlyTestResponse,
}
