import React from 'react'
import styled from 'styled-components'
import { Grid, Stack } from '@mui/material'
import { theme, Typography } from 'flanders-common-ui'
import ComplianceReportRow from './ComplianceReportRow'
import YearlyTestingSectionHeader from './YearlyTestingSectionHeader'
import type { YearlyTest } from 'api/tests'
import { getEstimatedCompletitionDate } from 'utils/date-utils'

const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${theme.spacing(5)};
  td,
  th {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  th {
    background-color: ${theme.palette.grey[700]};
    color: white;
  }
  .location-even {
    background-color: ${theme.palette.grey.A100};
  }
`

type YearlyTestingSectionProps = {
  yearlyTest: YearlyTest
  verifiedCount: number
}

const YearlyTestingSection: React.FC<YearlyTestingSectionProps> = ({
  yearlyTest,
  verifiedCount,
}) => {
  let isOdd = true
  let prevLocation = ''
  const requiresAttentionCount = yearlyTest.testResults.reduce(
    (count, { status }) =>
      status !== 'passed' && status !== 'pending' ? count + 1 : count,
    0
  )

  const startedAt = yearlyTest.scheduledFor
  const testedCount = yearlyTest.testResults.length
  const remainingCount = verifiedCount - testedCount
  const estimatedCompletition = getEstimatedCompletitionDate(
    startedAt,
    remainingCount
  )

  return (
    <Stack
      key={yearlyTest.scheduledFor.toString()}
      marginBottom={theme.units.medium}
    >
      <Grid
        container
        item
        justifyContent={'space-between'}
        marginBottom={theme.units.small}
      >
        <Grid
          container
          item
          justifyContent={'space-between'}
          marginBottom={theme.units.small}
        >
          <YearlyTestingSectionHeader
            startedAt={startedAt}
            estimatedCompletition={estimatedCompletition}
            testedCount={testedCount}
            remainingCount={remainingCount}
            requiresAttentionCount={requiresAttentionCount}
          />
        </Grid>

        <StyledTable>
          <thead>
            <tr>
              <th>Location</th>
              <th>Date/Time</th>
              <th>Type</th>
              <th>Duration</th>
              <th>Result</th>
              <th
                style={{
                  textAlign: 'right',
                }}
              >
                Requires Attention
              </th>
            </tr>
          </thead>

          <tbody>
            {yearlyTest.testResults.map((testResult) => {
              if (testResult.location !== prevLocation) {
                isOdd = !isOdd
                prevLocation = testResult.location
              }

              return yearlyTest.testResults.map((testResult, index) => {
                const isMostRecentTestPerLocation = index === 0
                const requiresAttention =
                  isMostRecentTestPerLocation &&
                  testResult.status !== 'passed' &&
                  testResult.status !== 'pending'
                const resultData = {
                  isMostRecentTestPerLocation: isMostRecentTestPerLocation,
                  locationOdd: isOdd,
                  locationName: isMostRecentTestPerLocation
                    ? testResult.location
                    : ` ▸ ${testResult.location}`,
                  requiresAttention: requiresAttention,
                  startedAt: testResult.startedAt,
                  scheduledType: testResult.scheduledType,
                  status: testResult.status,
                  testType: testResult.testType,
                }

                return (
                  <ComplianceReportRow key={testResult.id} {...resultData} />
                )
              })
            })}
          </tbody>
        </StyledTable>

        <Stack width="100%">
          <Stack
            p={5}
            mb={2.5}
            width="100%"
            alignItems="center"
            borderRadius={1}
            borderColor={theme.palette.grey[600]}
            sx={{ borderWidth: '1px', borderStyle: 'dashed ' }}
          >
            <Typography fontSize={24} weight="semiBold">
              {remainingCount} Locations Remaining*
            </Typography>
            <Typography color={theme.palette.grey[600]}>
              Estimated Completion: {estimatedCompletition}*
            </Typography>
          </Stack>
          <Stack width="100%" alignItems="center">
            <Typography
              color={theme.palette.grey[600]}
              fontStyle="italic"
              variant="body"
            >
              {
                "A location's 90 minute test is scheduled 1 day after the previous test until all locations have been tested"
              }
            </Typography>
            <Typography
              color={theme.palette.grey[600]}
              fontStyle="italic"
              variant="body"
            >
              {
                '*Remaining locations & completion date may change if power outages occur or fixtures are added / removed.'
              }
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  )
}

export default YearlyTestingSection
