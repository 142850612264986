import { YearlyTest } from 'api/tests'

export const getTestStatusTotal = (
  tests: YearlyTest[]
): { pending: number; completed: number } =>
  tests
    .flatMap((test) => test.testResults)
    .reduce(
      (acc, result) => {
        if (result.status === 'pending') {
          acc.pending++
        }

        if (result.status === 'failed' || result.status === 'passed') {
          acc.completed++
        }

        return acc
      },
      { pending: 0, completed: 0 }
    )
