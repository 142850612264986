import React from 'react'
import { Grid, Skeleton, Stack } from '@mui/material'
import { theme, logoImage, Typography } from 'flanders-common-ui'
import { formatDate, SHORT_MONTH_YEAR_DATE_TIME_FORMAT } from 'utils/date-utils'
import type { YearlyTest } from 'api/tests'
import YearlyTestingSection from './YearlyTestingSection'
import { getTestStatusTotal } from 'utils/test-result-utils'

export type YearlyComplianceReportProps = {
  yearlyTests?: YearlyTest[]
  verifiedCount: number
  isLoading?: boolean
  gatewayId: string
}

const YearlyComplianceReport: React.FC<YearlyComplianceReportProps> = ({
  yearlyTests = [],
  isLoading,
  verifiedCount,
  gatewayId,
}) => {
  const { pending, completed } = getTestStatusTotal(yearlyTests)

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid container item alignItems="space-between">
        <Grid item xs={6}>
          <Typography variant="body2" color={theme.palette.grey[600]}>
            {gatewayId}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Generated:{' '}
            {formatDate(Date.now(), SHORT_MONTH_YEAR_DATE_TIME_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        flexDirection="column"
        alignItems={'center'}
        marginBottom={12}
      >
        <img
          src={logoImage}
          alt="EMC Logo"
          height="45"
          style={{
            marginBottom: `${theme.units.large}px`,
            filter: 'grayscale(100%)',
          }}
        />
        <Typography variant="h1" fontWeight="bold">
          90 Minute Emergency Lighting Compliance Report
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            animation="wave"
            height={40}
          />
        ) : (
          <Typography
            display="flex"
            variant="body2"
            color={theme.palette.grey[600]}
          >
            <Typography mr={2.5}>
              <strong>{pending}</strong> Pending
            </Typography>
            <Typography>
              <strong>{completed}</strong> Completed
            </Typography>
          </Typography>
        )}
      </Grid>

      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={470}
          animation="wave"
        />
      ) : (
        yearlyTests.map((yearlyTest: YearlyTest) => {
          return (
            <Stack
              key={yearlyTest.scheduledFor.toString()}
              marginBottom={theme.units.medium}
              width={'100%'}
              maxWidth={1440}
            >
              <YearlyTestingSection
                yearlyTest={yearlyTest}
                verifiedCount={verifiedCount}
              />
            </Stack>
          )
        })
      )}
    </Grid>
  )
}

export default YearlyComplianceReport
