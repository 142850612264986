import { apiClient, GenericErrorResponse } from 'api/http-common'
import type { APICurriedFunction } from 'api/http-common'
import type {
  YearlyTestResponse,
  YearlyTest,
  YearlyTestsRequestParams,
} from 'api/tests'
import { mapYearlyTestResponseToYearlyTest } from './mapYearlyTestResponseToYearlyTest'

export const fetchYearlyTests: APICurriedFunction<
  YearlyTestsRequestParams,
  YearlyTest[]
> =
  ({ organizationId, siteId }) =>
  async () => {
    try {
      const { data, headers } = await apiClient.get<YearlyTestResponse[]>(
        `/organizations/${organizationId}/sites/${siteId}/test_cycles_results/yearly_tests`
      )

      return data
        .map((response) => ({
          ...response,
          gateway_id: headers['flanders-gateway-firmware-serial-number'] ?? '',
        }))
        .map(mapYearlyTestResponseToYearlyTest)
    } catch (error) {
      const axiosError = error as GenericErrorResponse
      const { data } = axiosError.response ?? {}
      const defaultErrorMessage =
        'Error fetching test_cycles_results/yearly_tests data'

      console.error(defaultErrorMessage, error)

      throw data?.message ?? [defaultErrorMessage]
    }
  }
