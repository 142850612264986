import React, { useState } from 'react'
import { Button, ContentHeader, DetailsPanel, theme } from 'flanders-common-ui'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { UpcomingTest } from 'api/tests'
import { Page } from 'api/page'
import UpcomingTestsTable from './UpcomingTestsTable'

type UpcomingTestsPanelType = {
  body: string
  data: Page<UpcomingTest> | undefined
  heading: string
  isLoading: boolean
  title: string
  verifiedCount?: number
  totalCount?: number
}

const UpcomingTestsPanel: React.FC<UpcomingTestsPanelType> = ({
  body,
  data,
  heading,
  isLoading,
  title,
  verifiedCount,
  totalCount,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const totalTests = data?.total_entries ?? 0

  const handleClickViewAll = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  return (
    <DetailsPanel data-test-id="upcoming-tests-panel" heading={heading}>
      <UpcomingTestsTable
        pageSize={5}
        isLoading={isLoading}
        upcomingTests={data}
        verifiedCount={verifiedCount}
        totalCount={totalCount}
      />

      {totalTests > 5 && (
        <>
          <div style={{ alignSelf: 'flex-end', marginTop: 0 }}>
            <Button variant="text" onClick={handleClickViewAll}>
              View All
            </Button>
          </div>

          <Dialog
            fullWidth
            maxWidth="xs"
            open={dialogOpen}
            onClose={handleCloseDialog}
          >
            <DialogContent>
              <ContentHeader title={title} body={body} />

              <UpcomingTestsTable
                pageSize={100}
                isLoading={isLoading}
                upcomingTests={data}
                verifiedCount={verifiedCount}
                totalCount={totalCount}
              />
            </DialogContent>

            <DialogActions sx={{ paddingBottom: `${theme.units.xl}px` }}>
              <Button fullWidth onClick={handleCloseDialog}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </DetailsPanel>
  )
}

export default UpcomingTestsPanel
