import React from 'react'
import { Skeleton, Stack } from '@mui/material'
import {
  ComplianceReportGenerator,
  DetailsPanel,
  TestCycle,
  theme,
  Typography,
} from 'flanders-common-ui'
import { formatDate } from 'utils/date-utils'
import { LocationTestType } from 'api/tests'

export type ComplianceReportPanelProps = {
  isLoading: boolean
  testCycles?: TestCycle[]
  onSubmit: (type: LocationTestType, start: string, end: string) => void
}

const ComplianceReportPanel: React.FC<ComplianceReportPanelProps> = ({
  isLoading,
  testCycles,
  onSubmit,
}) => {
  const noRecordsAvailable = !testCycles || testCycles.length === 0

  return (
    <DetailsPanel
      data-test-id="compliance-report-panel"
      heading="Compliance Report"
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={211}
          animation="wave"
        />
      ) : noRecordsAvailable ? (
        <Stack alignItems={'center'} textAlign={'center'} padding={2}>
          <Typography
            variant="h4"
            fontWeight={'bold'}
            color={theme.palette.grey[800]}
          >
            No Reports Available
          </Typography>
          <Typography variant="bodySmall" color={theme.palette.grey[600]}>
            The system hasn&apos;t run any automated tests which are required to
            generate reports
          </Typography>
        </Stack>
      ) : (
        <ComplianceReportGenerator
          testCycles={testCycles}
          onSubmit={onSubmit}
          dateFormatter={formatDate}
        />
      )}
    </DetailsPanel>
  )
}

export default ComplianceReportPanel
