import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useToastAlertProvider } from 'flanders-common-ui'
import YearlyComplianceReport from './YearlyComplianceReport'
import { useFetchSiteOverviewById } from 'api/locations'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'
import { getSiteOverview } from 'utils/location-utils'
import { useFetchYearlyTests } from 'api/tests'

const YearlyComplianceReportPage: React.FC = () => {
  const { activeOrganization } = useActiveOrganizationContext()
  const { setToast } = useToastAlertProvider()
  const [params] = useSearchParams()

  const siteId = params.get('site_id') ?? ''

  const {
    data: yearlyTestsData,
    isLoading: isLoadingYearlyTests,
    error: errorFetchingYearlyTests,
  } = useFetchYearlyTests({
    organizationId: activeOrganization?.id ?? '',
    siteId,
  })

  const {
    isLoading: isLoadingSiteOverview,
    data: siteOverviewData,
    error: errorFetchingSiteOverview,
  } = useFetchSiteOverviewById({
    organizationId: activeOrganization?.id ?? '',
    siteId: siteId,
  })

  useEffect(() => {
    if (errorFetchingYearlyTests || errorFetchingSiteOverview) {
      setToast({
        message: 'Error fetching compliance report data',
        severity: 'error',
      })
    }
  }, [errorFetchingYearlyTests, errorFetchingSiteOverview, setToast])

  const gatewayId = yearlyTestsData ? yearlyTestsData[0]?.gatewayId : ''
  const { verifiedLocationCount } = getSiteOverview(siteOverviewData)

  return (
    <YearlyComplianceReport
      yearlyTests={yearlyTestsData}
      verifiedCount={verifiedLocationCount}
      isLoading={isLoadingYearlyTests || isLoadingSiteOverview}
      gatewayId={gatewayId}
    />
  )
}

export default YearlyComplianceReportPage
