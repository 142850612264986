import React from 'react'
import { Grid, Skeleton, Stack } from '@mui/material'
import { logoImage, theme, Typography } from 'flanders-common-ui'
import TestingCycleSection from './TestingCycleSection'
import type { TestCycleResults } from 'api/tests'
import { formatDate, SHORT_MONTH_YEAR_DATE_TIME_FORMAT } from 'utils/date-utils'

export type ComplianceReportProps = {
  testCycleResults?: TestCycleResults[]
  isLoading?: boolean
  gatewayId: string
}

const ComplianceReport: React.FC<ComplianceReportProps> = ({
  testCycleResults = [],
  isLoading,
  gatewayId,
}) => {
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid container item alignItems="space-between">
        <Grid item xs={6}>
          <Typography variant="body2" color={theme.palette.grey[600]}>
            {gatewayId}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="body2" color={theme.palette.grey[600]}>
            Generated:{' '}
            {formatDate(Date.now(), SHORT_MONTH_YEAR_DATE_TIME_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        flexDirection="column"
        alignItems={'center'}
        marginBottom={12}
      >
        <img
          width={102}
          src={logoImage}
          alt="ELTS Logo"
          style={{
            marginTop: '0 !important',
            filter: 'grayscale(100%)',
          }}
        />
        <Typography variant="h1" fontWeight="bold">
          30 Second Emergency Lighting Compliance Report
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            animation="wave"
            height={40}
          />
        ) : (
          <Typography color={theme.palette.grey[800]}>
            {testCycleResults.length} Test Cycles Between{' '}
            <strong>
              {formatDate(
                testCycleResults[testCycleResults.length - 1]?.beginningAt
              ) ?? 'Today'}
            </strong>{' '}
            and <strong>{formatDate(testCycleResults[0]?.beginningAt)}</strong>
          </Typography>
        )}
      </Grid>

      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={470}
          animation="wave"
        />
      ) : (
        testCycleResults.map((testCycle: TestCycleResults, index) => {
          // We show mostrecent -> leastrecent cycles, so we need to grab the start date of the previous cycle (because it is more recent)
          // This means the most recent cycle's end date must come from the ComplianceReportGenerator / API, since we don't have it anywhere else
          //TODO:FLAN-867 replace null with API data
          const endingAt =
            index === 0 ? null : testCycleResults[index - 1].beginningAt

          return (
            <Stack
              key={testCycle.id}
              marginBottom={theme.units.medium}
              width={'100%'}
              maxWidth={1440}
            >
              <TestingCycleSection
                testCycleResults={testCycle}
                endingAt={endingAt}
              />
            </Stack>
          )
        })
      )}
    </Grid>
  )
}

export default ComplianceReport
