import { YearlyTest, YearlyTestResponse } from 'api/tests'

export const mapYearlyTestResponseToYearlyTest = (
  data: YearlyTestResponse
): YearlyTest => {
  return {
    ...data,
    gatewayId: data.gateway_id,
    scheduledFor: data.scheduled_for,
    testResults: data.test_results.map((result) => ({
      ...result,
      startedAt: result.started_at,
      endedAt: result.ended_at,
      testType: data.test_type,
      scheduledType: data.scheduled_type,
    })),
  }
}
