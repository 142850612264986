import { SiteOverviewResponse } from 'api/locations'

export const getSiteOverview = (siteOverview?: SiteOverviewResponse) => {
  const healthy = siteOverview?.healthy ?? 0
  const failing = siteOverview?.failing ?? 0
  const offline = siteOverview?.offline ?? 0
  const unverified = siteOverview?.unverified ?? 0
  const running = siteOverview?.running ?? 0
  const sensorErrors = siteOverview?.sensorErrors ?? 0

  const requireAttention = failing + offline + unverified + sensorErrors
  const verifiedLocationCount = failing + healthy + offline + running
  const testableLocations = failing + healthy + offline + running + sensorErrors
  const totalLocationCount = healthy + running + requireAttention

  return {
    healthy,
    failing,
    offline,
    unverified,
    running,
    sensorErrors,
    requireAttention,
    verifiedLocationCount,
    testableLocations,
    totalLocationCount,
  }
}
