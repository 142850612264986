import React, { useState } from 'react'
import { Skeleton, capitalize } from '@mui/material'
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { UpcomingTest } from 'api/tests'
import { Page } from 'api/page'
import {
  Button,
  Typography,
  Duration30SecondIcon,
  Duration90MinuteIcon,
  theme,
} from 'flanders-common-ui'
import { dateTimeFromNow, formatDate } from 'utils/date-utils'

type TestTypeTableCellProps = {
  upcomingTest: UpcomingTest
  verifiedCount?: number
  totalCount?: number
}

const TestTypeTableCell: React.FC<TestTypeTableCellProps> = ({
  upcomingTest,
  verifiedCount,
  totalCount,
}) => (
  <>
    {upcomingTest.testType === '30sec' ? (
      <>
        <Duration30SecondIcon width={24} height={24} sx={{ marginRight: 1 }} />
        <Typography>
          {upcomingTest.isAutoScheduled ? 'Automatic' : 'Manual'}
        </Typography>
      </>
    ) : (
      <>
        <Duration90MinuteIcon width={24} height={24} sx={{ marginRight: 1 }} />
        <Typography>
          {totalCount !== undefined && verifiedCount !== undefined
            ? `${totalCount - verifiedCount} of ${totalCount}`
            : 'Automatic'}
        </Typography>
      </>
    )}
  </>
)

const getTableHeight = (rows: UpcomingTest[]) => {
  const rowHeight = 52
  const headerHeight = 56

  if (rows.length === 0) {
    return rowHeight + headerHeight
  } else if (rows.length > 5) {
    return 5 * rowHeight + headerHeight
  } else {
    return rows.length * rowHeight + headerHeight
  }
}

type LocationUpcomingTestsTableProps = {
  upcomingTests?: Page<UpcomingTest>
  pageSize: number
  isLoading?: boolean
  verifiedCount?: number
  totalCount?: number
}

type ScheduledForCellProps = {
  upcomingTest: UpcomingTest
}
const ScheduledForCell: React.FC<ScheduledForCellProps> = ({
  upcomingTest,
}) => {
  const [showFromNow, setShowFromNow] = useState(true)
  const formattedFromNow: string = dateTimeFromNow(upcomingTest.scheduledFor)

  return (
    <Button
      sx={{
        padding: 0,
        fontWeight: 400,
        color: theme.palette.grey[900],
        fontSize: '14px',
      }}
      variant="text"
      onClick={() => setShowFromNow(!showFromNow)}
    >
      {`${
        showFromNow
          ? capitalize(formattedFromNow)
          : formatDate(upcomingTest.scheduledFor)
      }${upcomingTest.testType === '90min' ? '*' : ''}`}
    </Button>
  )
}

const UpcomingTestsTable: React.FC<LocationUpcomingTestsTableProps> = ({
  upcomingTests,
  pageSize,
  isLoading = true,
  verifiedCount,
  totalCount,
}) => {
  const columns: GridColDef<UpcomingTest>[] = [
    {
      field: 'testType',
      flex: 2,
      headerName: 'TYPE',
      renderCell: ({ row: upcomingTest }) => (
        <TestTypeTableCell
          upcomingTest={upcomingTest}
          verifiedCount={verifiedCount}
          totalCount={totalCount}
        />
      ),
    },
    {
      field: 'scheduledFor',
      flex: 2,
      headerName: 'Scheduled to Run',
      renderCell: ({ row: upcomingTest }) => (
        <ScheduledForCell upcomingTest={upcomingTest} />
      ),
    },
  ]

  if (isLoading || !upcomingTests) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={400}
        animation="wave"
      />
    )
  }

  return (
    <div
      style={{
        height: getTableHeight(upcomingTests.entries),
        width: '100%',
      }}
    >
      <DataGrid
        hideFooter
        disableColumnFilter
        disableSelectionOnClick
        columns={columns}
        rows={upcomingTests.entries}
        page={0}
        pageSize={pageSize}
        components={{
          ColumnMenu: () => null,
          ColumnMenuIcon: () => null,
          ColumnSortedAscendingIcon: () => null,
          ColumnSortedDescendingIcon: () => null,
        }}
      />
    </div>
  )
}

export default UpcomingTestsTable
